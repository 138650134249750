.open-door {
  background: url('/img/entry-door/Entry-way.png') no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  width: 100%;
  height: 100vh;

  .header {
    z-index: 999;
  }

  .center-bg {
    display: flex;
    align-items: center;
    justify-content: center;
    animation: zoomin 10s ease-in infinite;
    transition: all 0.5s ease-in-out;
  }

  /* Zoom in Keyframes */
  @-webkit-keyframes zoomin {
    0% {
      transform: scale(0.5);
    }
    100% {
      transform: scale(1);
    }
  }
  @keyframes zoomin {
    0% {
      transform: scale(0.5);
    }
    100% {
      transform: scale(1);
    }
  } /*End of Zoom in Keyframes */
}
