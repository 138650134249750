.intro {
  background: url('/img/intro/Entry-way.png') no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  position: relative;
  .boot-more-barks {
    position: absolute;
    bottom: -15.625vw;
    z-index: 1;
  }

  .bot {
    position: absolute;
    bottom: 16.833333333333334vw;
    left: 10.416666666666667vw;
  }

  .flying-bot {
    position: absolute;
    left: 18.666666666666666vw;
    bottom: 11.71875vw;
  }
}
