.walletModal {
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 999;
  opacity: 1;
  backdrop-filter: blur(15px);
  -webkit-backdrop-filter: blur(15px);

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .title {
    font: normal normal bold 28px/20px TyprighterV2;
    letter-spacing: -1px;
    opacity: 1;
    text-align: center;
  }

  .wallet {
    margin-top: 20px;
    display: flex;
    .common {
      cursor: pointer;
      border: 2px solid rgba(255, 255, 255, 0.2);
      border-radius: 8px;
      width: 300px;
      height: 300px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      font: normal normal bold 18px/20px typrighter;
      text-shadow: 0px 3px 6px #00000029;
      margin-top: 20px;
      margin-left: 82px;
    }

    .common:hover {
      opacity: 0.7;
    }

    .metamask {
      margin-right: 4px;
    }

    .walletConnect {
      margin-left: 4px;
    }
  }
}
