@font-face {
  font-family: 'typrighter';
  src: url(./font/typrighter/typrighter-B.otf);
}

@font-face {
  font-family: 'typrighterV2';
  src: url(./font/typrighter/typrighterV2-1.otf);
}

@font-face {
  font-family: 'traveler';
  src: url(./font/typrighter/Traveler.ttf);
}

@font-face {
  font-family: 'enigma';
  src: url(./font/enigma/EnigmaSans.ttf);
}

html,
body {
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue,
    sans-serif;
  background: #000 !important;
  scrollbar-3dlight-color: transparent;
  scrollbar-highlight-color: transparent;
  scrollbar-face-color: transparent;
  scrollbar-arrow-color: transparent;
  scrollbar-shadow-color: transparent;
  scrollbar-darkshadow-color: transparent;
  scrollbar-base-color: transparent;
  scrollbar-track-color: transparent;
}


div::-webkit-scrollbar-track {
  background-color: transparent;
}

div::-webkit-scrollbar-thumb {
  background-color: transparent;
}

div::-webkit-scrollbar-button {
  background-color: transparent;
}

div::-webkit-scrollbar-corner {
  background-color: transparent;
}


a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

.chakra-checkbox__control {
  width: 22px !important;
  height: 22px !important;
  border-color: #ce00f4 !important;
}

.chakra-checkbox__control[aria-checked='true'],
.chakra-checkbox__control[data-checked] {
  background: #ce00f4 !important;
}